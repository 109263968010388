import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import { Breadcrambs } from "components"
import S from "./SubpageLayout.styled"

const SubpageLayout = ({ path, children }) => {
  const [crambs, setCrambs] = useState([])

  useEffect(() => {
    if (path && path[path.length - 1] === "/") {
      const newPath = [...path]
      newPath.pop()
      navigate(newPath.join(""))
    }
  }, [path])

  useEffect(() => {
    if (path.startsWith("/en/")) {
      const splitPath = path.split("/en/")

      const crambsOutput = splitPath.map((link, index) => {
        if (index === 0)
          return {
            path: "/en",
            label: "home",
          }

        if (index === 1)
          return {
            path: `/en/${link}`,
            label: link.split("-").join(" "),
          }

        return {
          path: "",
          label: "",
        }
      })

      setCrambs(crambsOutput)
    } else {
      const splitPath = path.split("/")

      const crambsOutput = splitPath.map((link, index) => {
        if (index === 0)
          return {
            path: "/",
            label: "principal",
          }

        if (index === 1)
          return {
            path: `/${link}`,
            label: link.split("-").join(" "),
          }

        return {
          path: "",
          label: "",
        }
      })

      setCrambs(crambsOutput)
    }
  }, [path])

  return (
    <S.Wrapper>
      <Breadcrambs crumbs={crambs} />
      {children}
    </S.Wrapper>
  )
}

SubpageLayout.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SubpageLayout
