import React from "react"
import PropTypes from "prop-types"

import AboutTemplate from "templates/AboutTemplate"

const AcercaDePage = ({ location }) => (
  <AboutTemplate location={location} lang="ES" />
)

AcercaDePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AcercaDePage
