import React, { useRef, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import { gsap } from "gsap"

import SubpageLayout from "layouts/SubpageLayout"
import { ImageSection, SEO, Footer } from "components"
import { HeadlineSizes, Loader } from "ui"

const query = graphql`
  {
    allWpPage(filter: { about: { aboutLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          title
          about {
            aboutLang
            aboutSection1 {
              title
              description
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
            aboutSection2 {
              title
              description
              imageNo1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
              imageNo2 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
            aboutSection3 {
              title
              description
              imageNo1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
              imageNo2 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const AboutTemplate = ({ location, lang }) => {
  const { allWpPage: { edges: languageDataVersions } } = useStaticQuery(query) // eslint-disable-line
  const [pageContent, setPageContent] = useState(null)
  const [loading, setLoading] = useState(true)

  const firstSectionRef = useRef(null)
  const secondSectionRef = useRef(null)
  const thirdSectionRef = useRef(null)

  useEffect(() => {
    if (!firstSectionRef.current && !secondSectionRef.current && loading) return

    const firstSection = firstSectionRef.current
    const secondSection = secondSectionRef.current
    const thirdSection = thirdSectionRef.current

    const tl = gsap.timeline()

    tl.fromTo(
      firstSection,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
      }
    )

    gsap.fromTo(
      secondSection,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: firstSection,
          start: "bottom 50%",
        },
      }
    )

    gsap.fromTo(
      thirdSection,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: secondSection,
          start: "bottom 50%",
        },
      }
    )
  }, [firstSectionRef, thirdSectionRef, secondSectionRef, loading])

  useEffect(() => {
    const { node } = languageDataVersions.find(
      ({ node: { about } }) => about.aboutLang === lang
    )

    setPageContent({ ...node?.about, title: node?.title })

    const id = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(id)
  }, [])

  return (
    <>
      <SEO title={pageContent?.title || ""} />
      <SubpageLayout path={location?.pathname}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <ImageSection
              _ref={firstSectionRef}
              headline={{
                tag: "h2",
                sizeType: HeadlineSizes.XL,
                value: pageContent?.aboutSection1?.title || "",
              }}
              paragraphs={
                parse(pageContent?.aboutSection1?.description, {
                  trim: true,
                }) || null
              }
              images={[
                {
                  fluid:
                    pageContent?.aboutSection1?.image?.localFile || null,
                  margin: "40% 0 0",
                },
              ]}
            />
            <ImageSection
              reverse
              _ref={secondSectionRef}
              headline={{
                tag: "h2",
                sizeType: HeadlineSizes.XL,
                value: pageContent?.aboutSection2?.title || "",
              }}
              paragraphs={
                parse(pageContent?.aboutSection2?.description, {
                  trim: true,
                }) || null
              }
              images={[
                {
                  fluid:
                    pageContent?.aboutSection2?.imageNo1?.localFile || null,
                  margin: "20% 0 0",
                  width: "80%",
                },
                {
                  fluid:
                    pageContent?.aboutSection2?.imageNo2?.localFile || null,
                  margin: "-20% 0 0 auto",
                  width: "80%",
                },
              ]}
            />
            <ImageSection
              _ref={thirdSectionRef}
              headline={{
                tag: "h2",
                sizeType: HeadlineSizes.XL,
                value: pageContent?.aboutSection3?.title || "",
              }}
              paragraphs={
                parse(pageContent?.aboutSection3?.description, {
                  trim: true,
                }) || null
              }
              images={[
                {
                  fluid:
                    pageContent?.aboutSection3?.imageNo1?.localFile || null,
                    margin: "20% 0 0",
                    width: "80%",
                },
                {
                  fluid:
                    pageContent?.aboutSection3?.imageNo2?.localFile || null,
                    margin: "-20% 0 0 auto",
                    width: "80%",
                },
              ]}
            />
            <Footer />
          </>
        )}
      </SubpageLayout>
    </>
  )
}

AboutTemplate.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
}

export default AboutTemplate
